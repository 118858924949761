
























import { formatarMoeda } from '@/shareds/formatadores'
import { Vue, Component, Prop } from 'vue-property-decorator'

export type FluxoDeCaixa = {
	nome: string
	valor: number
	tipo: '-' | '+' | '=' | ' '
	notaDeCalculo?: string
}

@Component
export default class ItemDoResumo extends Vue {
	@Prop() item!: FluxoDeCaixa

	formatarMoeda = formatarMoeda
}

