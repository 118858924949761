import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import pdfMake from 'pdfmake/build/pdfmake'
import type { TDocumentDefinitions } from 'pdfmake/interfaces'
import vfs from 'pdfmake/build/vfs_fonts.js'
import { formatarMoeda } from '@/shareds/formatadores'

import {
	TurnoDeVenda,
	FechamentoDeCaixaDetalhado,
} from '@/models'

import print from 'print-js'

window.pdfMake.vfs = vfs.pdfMake.vfs

async function criarDocumentoDeFechamentoDoCaixa(
	turnoDeVenda: TurnoDeVenda,
	fechamentoDeCaixaDetalhado: FechamentoDeCaixaDetalhado,

): Promise<string> {
	
	const dataHoraDoFechamento = fechamentoDeCaixaDetalhado.dataHoraFechamento || new Date()
	const dataHoraDeAbertura = turnoDeVenda.movimentacoes.find(
		({ tipoMovimentacao }) => tipoMovimentacao === 'Abertura',
	)?.dataHoraMovimentacao || ''

	const troco = fechamentoDeCaixaDetalhado.totalEmTrocos
	const cartao = fechamentoDeCaixaDetalhado.itensDeFechamento.find(x => x.forma === 'Cartão')
	const dinheiro = fechamentoDeCaixaDetalhado.totaisPorForma.find(x => x.forma === 'Dinheiro')

	const totalFaturado = fechamentoDeCaixaDetalhado.totalEmVendas

	const valorMovimentacoes =  fechamentoDeCaixaDetalhado.turnoDeVenda.movimentacoes
		.filter(movimentacao => movimentacao.tipoMovimentacao !== 'Abertura').reduce(
			(total, movimentacao) => total + (movimentacao.tipoMovimentacao === 'Sangria' ? movimentacao.valor : movimentacao.valor), 0,
		)

	const valorEsperado = fechamentoDeCaixaDetalhado.totalEsperado
	const totalContado = fechamentoDeCaixaDetalhado.totalContado
	const diferenca = fechamentoDeCaixaDetalhado.totalDeDiferenca

	const total = fechamentoDeCaixaDetalhado.turnoDeVenda.movimentacoes.reduce(
		(total, movimentacao) => {
			if (!movimentacao.motivo || movimentacao.motivo.despesa) return total
			return movimentacao.tipoMovimentacao === 'Sangria'
				? movimentacao.valor
				: -movimentacao.valor
		},
		0,
	)

	const recebidoDoFinanceiro = totalContado + total

	const docDefinition: TDocumentDefinitions = {
		pageSize: {
			height: 'auto',
			width: 230,
		},

		content: [
			{ text: `Loja ${turnoDeVenda.pontoDeVenda?.loja.nomeFantasia || ''}\n\n`, bold: true, fontSize: 9, alignment: 'center' },
			{ text: `Ponto de Venda ${turnoDeVenda.pontoDeVenda.nome}`, style: 'subHeader' },
			{ text: `Operador ${turnoDeVenda.operador.nome}`, style: 'subHeader' },
			{ text: `Abertura ${dateTimeToPtBrFormat(dataHoraDeAbertura)}`, style: 'subHeader' },
			{ text: `Fechamento ${dateTimeToPtBrFormat(dataHoraDoFechamento)}\n\n`, style: 'subHeader' },

			{ text: 'Resumo\n\n', bold: true, fontSize: 9, alignment: 'center' },
			{
				style: 'tableExample',
				table: {
					body: [
						['Cartão', 'Dinheiro', 'Troco'],
						[`R$ ${JSON.stringify(cartao?.valorContado)}`, `R$ ${JSON.stringify(dinheiro?.valor)}`, `R$ ${JSON.stringify(troco)}\n\n`],

						['Total Faturado', 'Valor Abertura', 'Movimentações'],
						[`R$ ${JSON.stringify(totalFaturado)}`, `R$ ${JSON.stringify(dinheiro?.valor)}`, `R$ ${JSON.stringify(valorMovimentacoes)}\n\n`],

						['Total Esperado', 'Total no Caixa', 'Total de Diferença'],
						[`R$ ${JSON.stringify(valorEsperado)}`, `R$ ${JSON.stringify(totalContado)}`, `R$ ${JSON.stringify(diferenca)}\n\n` ],
					],
				}},
			{text: 'Recebido Financeiro\n', style: 'title' },
			{ text: `R$ ${JSON.stringify(recebidoDoFinanceiro)}\n\n`, fontSize: 6, bold: true, color: 'red'}, 
			{canvas: [ { type: 'line', x1: 0, y1: 0, x2: 150, y2: 0, lineWidth: 1 } ]},
		],

		styles: {
			subHeader: {
				fontSize: 8,
				italics: true,
				alignment: 'center',
			},
		
			title: {
				bold: true,
				fontSize: 6,
				alignment: 'left',
			},

			tableExample:{
				bold: true,
				fontSize: 6,
				alignment: 'center',
			},
		},
	}

	return new Promise(resolve => {
		pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result))
	})
}

export async function imprimirDetalhado(
	turnoDeVenda: TurnoDeVenda,
	fechamentoDeCaixaDetalhado: FechamentoDeCaixaDetalhado,
): Promise<void> {
	const fechamentoDeCaixaBase64: string = await criarDocumentoDeFechamentoDoCaixa(turnoDeVenda, fechamentoDeCaixaDetalhado)
	return new Promise((resolve, reject) => {
		print({
			printable: fechamentoDeCaixaBase64,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

async function criarDocumentoDeFechamentoDoCaixaFiscal(
	turnoDeVenda: TurnoDeVenda,
	fechamentoDeCaixaDetalhado: FechamentoDeCaixaDetalhado,

): Promise<string> {
	
	const dataHoraDoFechamento = fechamentoDeCaixaDetalhado.dataHoraFechamento || new Date()
	const dataHoraDeAbertura = turnoDeVenda.movimentacoes.find(
		({ tipoMovimentacao }) => tipoMovimentacao === 'Abertura',
	)?.dataHoraMovimentacao || ''

	const totalEmOperacoesNfce = fechamentoDeCaixaDetalhado.totalEmOperacoesNfce
	const totalEmPagamentosComCartao = fechamentoDeCaixaDetalhado.totalEmPagamentosComCartao
	const totalEmVenda = fechamentoDeCaixaDetalhado.somaTotalEmOperacoesAutorizadas

	const totalVendasPendentes = fechamentoDeCaixaDetalhado.totalOperacoesPendentes
	const totalEmVendasCanceladas = fechamentoDeCaixaDetalhado.somaTotalEmOperacoesCanceladas
	const totaisPorFormaDePagamentoComNotasValidas = fechamentoDeCaixaDetalhado.totaisPorFormaDePagamentoComNotasValidas
	const formaDePagamentoContent = totaisPorFormaDePagamentoComNotasValidas.map((forma) => {
		return [
			{ text: `${forma.forma}:`, style: 'subHeader1' },
			{ text: `R$ ${formatarMoeda(forma.valor)}`, style: 'subHeader2' },
		];
	});

	const calcularTotaisPorForma = totaisPorFormaDePagamentoComNotasValidas.reduce((total, item) => {
		if (item.forma) {
			return total + item.valor;
		}
		return total;
	}, 0)

	const totalEmVendasAutorizadas = fechamentoDeCaixaDetalhado.totalEmOperacoesAutorizadas
	const totalOperacoesCanceladas = fechamentoDeCaixaDetalhado.totalOperacoesCanceladas
	const totalOperacoesRejeitadas = fechamentoDeCaixaDetalhado.totalEmOperacoesRejeitadas	

	const docDefinition: TDocumentDefinitions = {
		pageSize: {
			height: 'auto',
			width: 270,
		},

		content: [
			{ text: `Relatório de Fechamento de Caixa\n\n`, bold: true, fontSize: 8, alignment: 'center' },			
			{ text: `Abertura: ${dateTimeToPtBrFormat(dataHoraDeAbertura)}`, fontSize: 7, alignment: 'left' },
			{ text: `Fechamento: ${dateTimeToPtBrFormat(dataHoraDoFechamento)}`, fontSize: 7, alignment: 'left' },
			{ text: `Loja: ${turnoDeVenda.pontoDeVenda?.loja.nomeFantasia || ''}`, bold: true, fontSize: 7, alignment: 'left' },
			{ text: `Ponto de Venda: ${turnoDeVenda.pontoDeVenda.nome}`, fontSize: 7, alignment: 'left' },
			{ text: `Operador: ${turnoDeVenda.operador.nome}\n\n`, fontSize: 7, alignment: 'left' },
			{ text: `Total de Operações - NFC-e/NF-e`,  bold: true, fontSize: 7, alignment: 'left' },
			{
				columns: [
					{ text: `NFC-e/NF-e:`, fontSize: 7, alignment: 'left' },
					{ text: `${totalEmOperacoesNfce}`, fontSize: 7, alignment: 'right' },
				],
			},
			{
				columns: [
					{ text: `Comprovantes de cartão (Crédito/Debito):`, fontSize: 7, alignment: 'left' },
					{ text: `${totalEmPagamentosComCartao}\n\n\n`, fontSize: 7, alignment: 'right' },
				],
			},
			{ text: `Total de Vendas Autorizadas`,  bold: true, fontSize: 7, alignment: 'left' },
			{
				columns: [
					{ text: `Venda Total:`, fontSize: 7, alignment: 'left' },
					{ text: `R$ ${formatarMoeda(totalEmVenda)}\n\n`, fontSize: 7, alignment: 'right' },
				],
			},
			{ text: `Total de Vendas Não Autorizadas`,  bold: true, fontSize: 7, alignment: 'left' },
			{
				columns: [
					{ text: `Pendentes de Autorização:`, fontSize: 7, alignment: 'left' },
					{ text: `R$ ${formatarMoeda(totalVendasPendentes)}`, fontSize: 7, alignment: 'right' },
				],
			},
			{
				columns: [
					{ text: `Canceladas:`, fontSize: 7, alignment: 'left' },
					{ text: `R$ ${formatarMoeda(totalEmVendasCanceladas)}\n\n`, fontSize: 7, alignment: 'right' },
				],
			},
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 190, y2: 0, lineWidth: 1 }] },
			{
				columns: [
					{ text: `Total:`, fontSize: 7, alignment: 'left' },
					{ text: `R$ ${formatarMoeda(totalEmVendasCanceladas + totalVendasPendentes)}\n\n`, fontSize: 7, alignment: 'right' },
				],
			},			
			{ text: `Total por Meio Pagamento (NFC-e/NF-e)`,  bold: true, fontSize: 7, alignment: 'left' },			
			...formaDePagamentoContent.map(item => ({ columns: item })),
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 190, y2: 0, lineWidth: 1 }] },
			{
				columns: [
					{ text: `Total:`, fontSize: 7, alignment: 'left' },
					{ text: `R$ ${formatarMoeda(calcularTotaisPorForma)}\n\n`, fontSize: 7, alignment: 'right' },
				],
			},
			{ text: `Total de Operações Realizadas Por Status - NFC-e/NF-e`,  bold: true, fontSize: 7, alignment: 'left' },			
			{
				columns: [
					{ text: `Autorizadas:`, fontSize: 7, alignment: 'left' },
					{ text: `${totalEmVendasAutorizadas}`, fontSize: 7, alignment: 'right' },
				],
			},
			{
				columns: [
					{ text: `Canceladas:`, fontSize: 7, alignment: 'left' },
					{ text: `${totalOperacoesCanceladas}`, fontSize: 7, alignment: 'right' },
				],
			},			
			{
				columns: [
					{ text: `Rejeitadas:`, fontSize: 7, alignment: 'left' },
					{ text: `${totalOperacoesRejeitadas}\n\n`, fontSize: 7, alignment: 'right' },
				],
			},
		],

		styles: {
			subHeader1: {
				fontSize: 7, 
				alignment: 'left',				
			},

			subHeader2: {
				fontSize: 7, 
				alignment: 'right',
				margin: [0, 0, 0, 7],			
			},
			total: {				
				margin: [5, 0, 7, 0],			
			},				
		},
	}

	return new Promise(resolve => {
		pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result))
	})
}

export async function imprimirDetalhadoRelatorioFechamento(
	turnoDeVenda: TurnoDeVenda,
	fechamentoDeCaixaDetalhado: FechamentoDeCaixaDetalhado,
): Promise<void> {
	const fechamentoDeCaixaBase64: string = await criarDocumentoDeFechamentoDoCaixaFiscal(turnoDeVenda, fechamentoDeCaixaDetalhado)
	return new Promise((resolve, reject) => {
		print({
			printable: fechamentoDeCaixaBase64,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}