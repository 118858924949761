


































import { Vue, Component, Ref } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FechamentoDeCaixa } from '@/models/venda/FechamentoDeCaixa'
import { uploadFilesToS3 } from '@/shareds/s3/files'
import { SaveFechamentoDeCaixaUseCase } from '@/usecases'

@Component({})
export default class ImportadorDeArquivos extends Vue {
	@Ref() uploadForm!: HTMLFormElement
	@Ref() fileInput!: {
		$el: HTMLInputElement
	}

	AlertModule = AlertModule

	mostra = false
	carregando = false
	arquivos: File[] = []
	fechamentoDeCaixa: FechamentoDeCaixa | null = null
	saveFechamentoDeCaixaUseCase = new SaveFechamentoDeCaixaUseCase()

	mostrar(fechamentoDeCaixa: FechamentoDeCaixa) {
		this.fechamentoDeCaixa = JSON.parse(JSON.stringify(fechamentoDeCaixa))
		this.mostra = true
		setTimeout(() => {
			this.uploadForm.resetValidation()
		})
	}

	async upload() {
		if (!this.uploadForm.validate()) return
		if (!this.fechamentoDeCaixa) return
		if (!this.arquivos || this.arquivos.length === 0) {
			AlertModule.setError('Selecione pelo menos um arquivo para anexar')
			return
		}
		try {
			this.carregando = true
			const informacoesDosArquivos = await uploadFilesToS3(
				this.arquivos, `fechamentos/${this.fechamentoDeCaixa?.id}`,
			)
			const arquivosComExtensao: string[] = informacoesDosArquivos.map(
				({ config }) => config.data.name,
			)
			arquivosComExtensao.forEach(arquivo => {
				this.fechamentoDeCaixa?.anexos.push(arquivo)
			})
			await this.saveFechamentoDeCaixaUseCase.update(this.fechamentoDeCaixa)
			AlertModule.setSuccess('Arquivo(s) anexado(s) com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.arquivos = []
			this.carregando = false
			this.mostra = false
		}
	}
}

interface PreSignedBody {
	fileName: string
	contentType: string
}

