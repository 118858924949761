




































































































import { Component, PropSync, Vue } from 'vue-property-decorator'
import { formatarDataHora, formatarMoeda } from '@/shareds/formatadores'
import { MovimentacaoDeCaixa } from '@/models'
import type { FechamentoDeCaixa  } from '@/models'
import moment from 'moment'
import BotaoDeDownloadDeAnexoDeFechamento from '@/components/venda/BotaoDeDownloadDeAnexoDeFechamento.vue'
import { criarMovimentacaoDeCaixa } from '@/shareds/venda-shareds'
import DialogoDeNovaMovimentacao from '@/components/venda/DialogoDeNovaMovimentacao.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { SaveFechamentoDeCaixaUseCase } from '@/usecases'

@Component({
	components:{
		BotaoDeDownloadDeAnexoDeFechamento,
		DialogoDeNovaMovimentacao,
		Confirmacao,
	},
})
export default class TabelaDeMovimentacoes extends Vue{
	@PropSync('value', { type: Object}) fechamento!: FechamentoDeCaixa

	saveFechamentoDeCaixaUseCase = new SaveFechamentoDeCaixaUseCase()
	
	formatarDataHora = formatarDataHora
	formatarMoeda = formatarMoeda
	baixandoAnexos = false
	movimentacao: MovimentacaoDeCaixa[] = []
	movimentacaoInicial = criarMovimentacaoDeCaixa()
	mostraNovaMovimentacao: boolean | null = null
	edicao = false

	get movimentacoesOrdenadas() {
		return [...this.fechamento.turnoDeVenda.movimentacoes].sort(
			(movimentacaoA, movimentacaoB) => moment(movimentacaoA.dataHoraMovimentacao).diff(movimentacaoB.dataHoraMovimentacao),
		)
	}

	get totalEmMovimentacoes() {
		const totalMovimentado = this.fechamento.turnoDeVenda.movimentacoes
			.filter(movimentacao => movimentacao.tipoMovimentacao !== 'Abertura')
			.reduce(
				(total, movimentacao) => total + (movimentacao.tipoMovimentacao === 'Sangria' ? -movimentacao.valor : movimentacao.valor), 0,
			)
		return totalMovimentado !== 0 ? totalMovimentado * -1 : 0
	}

	mostrarDialogoMovimentacao(movimentacao: MovimentacaoDeCaixa){
		this.movimentacaoInicial = movimentacao
		this.mostraNovaMovimentacao = true
	}

	atualizar(movimentacao: MovimentacaoDeCaixa) {
		const indice = this.fechamento.turnoDeVenda.movimentacoes
			.findIndex(({ id }) => id === movimentacao.id)
		if (indice === -1) return
		this.fechamento.turnoDeVenda.movimentacoes
			.splice(indice, 1, movimentacao)
	}

	excluirMovimentacao(movimentacao: MovimentacaoDeCaixa) {
		const indice = this.fechamento.turnoDeVenda.movimentacoes
			.findIndex(({ id }) => id === movimentacao.id)
		if (indice === -1) return
		this.fechamento.turnoDeVenda.movimentacoes
			.splice(indice, 1)
	}
}
