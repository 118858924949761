


































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import type { FechamentoDeCaixaDetalhado} from '@/models'
import type { FechamentoDeCaixaServiceAdapter } from '@/usecases'
import { formatarMoeda } from '@/shareds/formatadores'
import { Inject } from 'inversify-props'

@Component
export default class TabelaFiscalFechamento extends Vue {
	@Prop({ type: String }) idFechamento!: string
	@Prop({ type: String }) idLoja!: string
	@PropSync('value', { type: Object}) fechamento!: FechamentoDeCaixaDetalhado

	@Inject('FechamentoDeCaixaServiceAdapter')
	fechamentoDeCaixaServiceAdapter!: FechamentoDeCaixaServiceAdapter

	formatarMoeda = formatarMoeda		

	totaisPorForma() {
		const totais = this.calcularTotaisPorForma(this.fechamento.totaisPorFormaDePagamentoComNotasValidas);
 
		return this.formatarMoeda(totais)
	}

	calcularTotaisPorForma(totaisPorForma) {		
		return totaisPorForma.reduce((total, item) => {
			if (item.forma) {
				return total + item.valor;
			}
			return total;
		}, 0);
	}		
}
