












































































































































































































































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator'
import { obterTotalDaVenda } from '@/shareds/venda-shareds'
import { formatarMoeda } from '@/shareds/formatadores'
import { Inject } from 'inversify-props'
import type { PagamentoServiceAdapter, DetalhesDoPagamentoServiceAdapter, FechamentoDeCaixaServiceAdapter } from '@/usecases'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import { AxiosRequestConfig } from 'axios'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { DetalhesDoPagamento, Pagamento, Page, Pageable, TipoDePagamento, VendaDoFechamento } from '@/models'
import { ObserveVisibility } from 'vue-observe-visibility'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import SeletorDeBandeira from '@/views/application/venda/SeletorDeBandeira.vue'

@Component({
	components: {
		DataTableDeCrudPaginado,
		SeletorDeBandeira,
	},
	directives: {
		ObserveVisibility,
	},
})
export default class ListaDeVendasDoFechamento extends Vue {
	@Ref() dataTable!: DataTableDeCrudPaginado
	@Prop({ type: String }) idFechamento!: string
	@Prop({ type: String }) idLoja!: string
	@Ref() botaoConfirmarParcelas!: { $el: HTMLButtonElement }

	@Inject('FechamentoDeCaixaServiceAdapter')
	private fechamentoDeCaixaServiceAdapter!: FechamentoDeCaixaServiceAdapter

	@Inject('PagamentoServiceAdapter')
	private pagamentoServiceAdapter!: PagamentoServiceAdapter

	@Inject('DetalhesDoPagamentoServiceAdapter')
	private detalhesDoPagamentoService!: DetalhesDoPagamentoServiceAdapter

	formatarMoeda = formatarMoeda
	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	obterTotalDaVenda = obterTotalDaVenda

	direcaoDaOrdenacao = [true]

	carregando = false
	pagina: Page<VendaDoFechamento> | null = null
	paginaAtual = 1
	mostraEdicaoPagamento = false
	salvando = false
	pagamentoEmEdicao: Pagamento | null = null
	detalhesDePagamento: DetalhesDoPagamento | null = null
	tiposDePagamento: TipoDePagamento[] = []
	buscando = false
	podeAlterarQtdDeParcelas: boolean | false = false
	vendasFechamento: VendaDoFechamento[] = []
	expanded: string[] = [];

	headers = [
		{ text: 'ID', value: 'identificador', width: 'auto' },
		{ text: 'Valor', value: 'total', width: '128px', align: 'right' },
		{ text: '', value: 'tipoDeTransacao', width: '20px', cellClass: 'px-0', class: 'px-0' },
		{ text: 'Data e Hora', value: 'dataHora' },
	]

	@Watch('mostraEdicaoPagamento')
	onChangeMostraEdicaoPagamento() {
		if (this.mostraEdicaoPagamento) {
			this.buscarDetalhesDePagamento()
			this.buscarTiposDePagamento()
		}
	}

	async buscarTiposDePagamento() {
		if (!this.pagamentoEmEdicao) return

		try {
			this.buscando = true
			this.tiposDePagamento = await this.pagamentoServiceAdapter.buscarTipoDePagamentoPorForma(this.pagamentoEmEdicao?.tipoDePagamento.formaDePagamento, this.idLoja)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	async buscarDetalhesDePagamento() {
		if (!this.pagamentoEmEdicao || !this.pagamentoEmEdicao.detalhesDoPagamentoId) return

		try {
			this.buscando = true
			this.detalhesDePagamento = await this.pagamentoServiceAdapter.buscarDetalhesDoPagamento(this.pagamentoEmEdicao.detalhesDoPagamentoId)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	async buscarVendasDoFechamento(params: Pageable, config?: AxiosRequestConfig): Promise<Page<VendaDoFechamento>>{
		const pagina = await this.fechamentoDeCaixaServiceAdapter.getVendas(this.idFechamento, params,  config)
		this.vendasFechamento = pagina.content
		return pagina
	}

	carregarMais() {
		if (!this.pagina || this.pagina.last || this.carregando) return
		++this.paginaAtual
	}

	async editarPagamento(pagamento: Pagamento | any) {
		this.pagamentoEmEdicao = pagamento
		this.mostraEdicaoPagamento = true
		try {
			this.podeAlterarQtdDeParcelas = await this.pagamentoServiceAdapter.podeAlterarQtdDeParcelas(pagamento.id)
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	async salvar() {
		if(this.pagamentoEmEdicao) {
			this.salvarPagamento()
		}

		if(this.detalhesDePagamento) {
			this.salvarDetalhesDePagamento()
		}
	}

	async salvarPagamento() {
		if (!this.pagamentoEmEdicao) return
		try {
			this.salvando = true
			await this.pagamentoServiceAdapter.salvarPagamento(this.pagamentoEmEdicao)

			this.pagamentoEmEdicao.tipoDePagamento = this.tiposDePagamento.filter(tipoDePagamento => tipoDePagamento.id === this.pagamentoEmEdicao?.tipoDePagamento.id)[0]
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.mostraEdicaoPagamento = false
		}
	}

	async salvarDetalhesDePagamento() {
		if (!this.detalhesDePagamento) return
		try {
			this.salvando = true
			await this.detalhesDoPagamentoService.updateDetalhesDoPagamento(this.detalhesDePagamento.id, this.detalhesDePagamento)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.mostraEdicaoPagamento = false
		}
	}

	cancelarEdicao() {
		this.mostraEdicaoPagamento = false
	}

	get parcelasComTaxasFormatado() {
		if(!this.pagamentoEmEdicao) return []
		return  this.pagamentoEmEdicao.tipoDePagamento != null && this.pagamentoEmEdicao.tipoDePagamento.parcelasComTaxas != null
			? this.pagamentoEmEdicao.tipoDePagamento.parcelasComTaxas.map(parcelaComTaxa => parcelaComTaxa.parcelamentos)
				.sort((pA, pB) =>{
					if( pA < pB) return -1
					if( pA > pB) return 1
					return 0
				}) : []
	}

}
