var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fit-content"},[_c('v-container',{staticStyle:{"max-width":"900px"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('SeletorDeLojas',{attrs:{"label":"Loja","clearable":""},model:{value:(_vm.filtros.lojaId),callback:function ($$v) {_vm.$set(_vm.filtros, "lojaId", $$v)},expression:"filtros.lojaId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-autocomplete',{attrs:{"label":"PDV","items":_vm.pdvsOrdenados,"clearable":"","item-text":"nome","item-value":"id","disabled":!_vm.loja},model:{value:(_vm.filtros.pdvId),callback:function ($$v) {_vm.$set(_vm.filtros, "pdvId", $$v)},expression:"filtros.pdvId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('RangeDatePicker',{attrs:{"inicial":{
								label: 'Data',
								clearable: true,
								loading: _vm.carregando,
							},"final":{
								clearable: true,
								loading: _vm.carregando,
							}},model:{value:(_vm.filtros.datas),callback:function ($$v) {_vm.$set(_vm.filtros, "datas", $$v)},expression:"filtros.datas"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-autocomplete',{attrs:{"multiple":"","items":[
								'Pendente',
								'Concluído',
								'Inconsistente' ]},model:{value:(_vm.filtros.situacoes),callback:function ($$v) {_vm.$set(_vm.filtros, "situacoes", $$v)},expression:"filtros.situacoes"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"grey darken-2","text":"","type":"submit","disabled":_vm.carregando},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.limparFiltros.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-broom ")]),_vm._v(" Limpar Filtros ")],1),_c('v-spacer'),_c('v-btn',{ref:"btnPesquisar",attrs:{"filled":"","color":"primary","loading":_vm.carregando},on:{"click":_vm.aplicarFiltros}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2"},[_c('v-container',[_c('DataTableDeCrudPaginado',{ref:"dataTablePaginado",attrs:{"items":_vm.registrosFormatados,"headers":_vm.headers,"loading":_vm.carregando,"hideDelete":"","hideCreate":"","onBuscarPagina":_vm.buscarPagina,"footer-props":{ itemsPerPageOptions: [ 5, 10, 15, 30, 50 ] },"sort-by":"dataHoraFechamento","sort-desc":[true],"filtroInicial":_vm.filtrosFormatados},scopedSlots:_vm._u([{key:"item.dataHoraFechamento",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarDataHora(item.dataHoraFechamento))+" ")]}},{key:"item.diferenca",fn:function(ref){
							var item = ref.item;
return [_c('div',{class:[ 'text-right', {
								'font-weight-bold': item.diferenca <= -50,
								'red--text': item.diferenca <= -50,
							}]},[_vm._v(" "+_vm._s(_vm.formatarMoeda(item.diferenca))+" ")])]}},{key:"item.actions",fn:function(ref){
							var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('DialogoDeDetalhesDeFechamento',{attrs:{"idFechamento":item.id},on:{"input":_vm.atualizarFechamentoDeCaixa},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function () { return _vm.fechamentoSelecionado = item; }}},'v-icon',attrs,false),Object.assign({}, on, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Detalhar")])])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function () { return _vm.abrirDialogoDeAuditoriaDoFechamento(item); }}},'v-icon',attrs,false),on),[_vm._v(" mdi-gavel ")])]}}],null,true)},[_c('span',[_vm._v("Auditar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function () { return _vm.atualizarSituacaoAuditoriaDoFechamento(item); }}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-underline ")])]}}],null,true)},[_c('span',[_vm._v("Confirmar")])])],1)]}}],null,true)})],1)],1),_c('DialogoDeAuditoria',{ref:"dialogoDeAuditoria",on:{"confirmado":_vm.atualizarFechamentoDeCaixa}}),_c('ImportadorDeArquivos',{ref:"dialogoDeImportadorDeArquivos"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }