
















































































































































































import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import TabelaDeFechamento from './TabelaDeFechamento.vue'
import { FechamentoDeCaixaDetalhado, MovimentacaoDeCaixa, TipoMovimentacao, VendaDoFechamento } from '@/models'
import TabelaDeMovimentacoes from './TabelaDeMovimentacoes.vue'
import DetalhesDePagamento from './DetalhesDePagamento.vue'
import ListaDeVendasDoFechamento from './ListaDeVendasDoFechamento.vue'
import { FindTurnoDeVendaUseCase, FindFechamentoDeCaixaUseCase } from '@/usecases'
import { imprimirFechamentoDeCaixa } from '@/shareds/fechamentos/impressao-do-fechamento-de-caixa'
import { imprimirDetalhado, imprimirDetalhadoRelatorioFechamento } from '@/shareds/fechamentos/impressao-detalhada'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import ImportadorDeArquivos from '@/views/application/fechamentosdecaixa/ImportadorDeArquivos.vue'
import DialogoDeNovaMovimentacao from '../DialogoDeNovaMovimentacao.vue'
import { criarMovimentacaoDeCaixa } from '@/shareds/venda-shareds'
import { MovimentarCaixaUseCase, SaveFechamentoDeCaixaUseCase } from '@/usecases'
import { formatarDataHora, formatarMoeda } from '@/shareds/formatadores'
import ItemDoResumo, { FluxoDeCaixa } from './ItemDoResumo.vue'
import TabelaFiscalFechamento from './TabelaFiscalFechamento.vue'

@Component({
	components: {
		TabelaDeFechamento,
		DetalhesDePagamento,
		ImportadorDeArquivos,
		TabelaDeMovimentacoes,
		DialogoDeNovaMovimentacao,
		ListaDeVendasDoFechamento,
		ItemDoResumo,
		TabelaFiscalFechamento,
	},
	data() {
		return {
			fechamentoDetalhado: undefined,
		}
	},
})
export default class DialogoDeDetalhesDeFechamento extends Vue {
	@Ref() dialogoDeImportadorDeArquivos!: ImportadorDeArquivos
	@Prop({ type: String}) idFechamento!: string

	mostra: boolean | null = null

	fechamentoDetalhado!: FechamentoDeCaixaDetalhado

	formatarDataHora = formatarDataHora
	formatarMoeda = formatarMoeda

	tab = null
	tabs = ['Resumo', 'Fechamento', 'Movimentações', 'Pagamentos', 'Vendas', 'Fiscal']
	movimentacaoInicial = criarMovimentacaoDeCaixa()
	movimentarCaixaUseCase = new MovimentarCaixaUseCase()
	findTurnoDeVendaUseCase = new FindTurnoDeVendaUseCase()
	findFechamentoUseCase = new FindFechamentoDeCaixaUseCase()
	mostrarDialogoDeNovaMovimentacao: boolean | null = null
	saveFechamentoDeCaixaUseCase = new SaveFechamentoDeCaixaUseCase()
	vendaFechamento: VendaDoFechamento | null = null

	carregando = true
	erroAoCarregar: Error | null = null

	get resumosPorMovimentacao(): FluxoDeCaixa[] {
		if (!this.fechamentoDetalhado) return []
		const totalMovimentadoEmLancamentos = this.fechamentoDetalhado.turnoDeVenda.movimentacoes
			.filter(movimentacao => movimentacao.tipoMovimentacao !== 'Abertura')
			.reduce(
				(total, movimentacao) => total + (movimentacao.tipoMovimentacao === 'Sangria' ? -movimentacao.valor : movimentacao.valor), 0,
			)
		const totalAbertura = this.fechamentoDetalhado.turnoDeVenda.movimentacoes
			.filter(({ tipoMovimentacao }) => tipoMovimentacao === 'Abertura')
			.map(({ valor }) => valor)[0]
		return [
			{
				nome: 'Abertura',
				valor: totalAbertura,
				tipo: '+',
			},
			{
				nome: 'Movimentações',
				valor: totalMovimentadoEmLancamentos !== 0
					? totalMovimentadoEmLancamentos * -1
					: 0,
				tipo: '-',
			},
			{
				nome: 'Total Esperado',
				valor: this.fechamentoDetalhado.totalEsperado,
				tipo: '=',
				notaDeCalculo: 'Total Faturado + Abertura - Sangria + Reforço',
			},
		]
	}

	mostraTextoDaMovimentacao({ motivo, tipoMovimentacao }: MovimentacaoDeCaixa) {
		if (!motivo) return tipoMovimentacao
		return `${motivo.nome}${!motivo.despesa && '*'}`
	}

	get resumosPorPagamento(): FluxoDeCaixa[] {
		if (!this.fechamentoDetalhado) return []
		return [
			...this.fechamentoDetalhado.totaisPorForma.map<FluxoDeCaixa>(
				({
					forma,
					valor,
				}) => ({
					nome: forma,
					valor,
					tipo: forma === 'Consignado' || forma === 'Demonstração' ? ' ' : valor < 0 ? '-': '+',
					notaDeCalculo: forma === 'Consignado' || forma === 'Demonstração' ? 'Forma de pagamento não inclusa no calculo total' : 'Total por forma (exceto vendas canceladas)',
				}),
			),
			{
				nome: 'Troco',
				valor: Math.abs(this.fechamentoDetalhado.totalEmTrocos),
				tipo: -this.fechamentoDetalhado.totalEmTrocos >= 0 ? '+' : '-',
				notaDeCalculo: 'Total Faturado - Total pago (Cartão + Dinheiro + Outros)',
			},
			{
				nome: 'Taxas',
				valor: Math.abs(this.fechamentoDetalhado.totalEmTaxas),
				tipo: '+',
				notaDeCalculo: 'Apenas taxas',
			},
			{
				nome: 'Total Faturado',
				valor: this.fechamentoDetalhado.totalEmVendas,
				tipo: '=',
				notaDeCalculo: 'Soma das vendas (considerando descontos + taxas)',
			},
		]
	}

	get resumosDoCaixa(): FluxoDeCaixa[] {
		if (!this.fechamentoDetalhado) return []
		return [
			{
				nome: 'Total no Caixa',
				valor: this.fechamentoDetalhado.totalContado,
				tipo: '=',
				notaDeCalculo: 'Valor apontado na caixa registradora',
			},
			{	
				nome: 'Diferença',
				valor: this.fechamentoDetalhado.totalDeDiferenca,
				tipo: '=',
				notaDeCalculo: 'Total no Caixa - Total Esperado',
			},
		]
	}

	get totalSemDespesa() {
		const total = this.fechamentoDetalhado.turnoDeVenda.movimentacoes.reduce(
			(total, movimentacao) => {
				if (!movimentacao.motivo || movimentacao.motivo.despesa) return total
				return movimentacao.tipoMovimentacao === 'Sangria'
					? movimentacao.valor
					: -movimentacao.valor
			},
			0,
		)
		return total
	}

	get resumoDoTotalDeVendasCanceladas(): FluxoDeCaixa | null {
		if (!this.fechamentoDetalhado) return null
		return {
			nome: 'Vendas Canceladas',
			valor: this.fechamentoDetalhado.totalEmVendasCanceladas,
			tipo: '-',
			notaDeCalculo: 'Vendas canceladas, tanto durante quanto fora do turno de vendas, devem ser observadas, lembrando que aquelas canceladas fora do turno ainda serão registradas no fechamento',
		}
	}

	get resumoRecebidoNoFinanceiro(): FluxoDeCaixa | null {
		if (!this.fechamentoDetalhado) return null
		return {
			nome: 'Recebido Financeiro',
			valor: this.fechamentoDetalhado.totalContado + this.totalSemDespesa,
			tipo: '=',
			notaDeCalculo: 'Total no Caixa + Movimentações que não são despesas',
		}
	}

	async reimprimirFechamentoDeCaixa(){
		const turnoDeVenda = (await this.findTurnoDeVendaUseCase.find({ fechamento: this.idFechamento })).content[0]
		try {
			await imprimirFechamentoDeCaixa(turnoDeVenda, this.fechamentoDetalhado)
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	async imprimirDetalhado(){
		const turnoDeVenda = (await this.findTurnoDeVendaUseCase.find({ fechamento: this.idFechamento })).content[0]
		try {
			await imprimirDetalhado(turnoDeVenda, this.fechamentoDetalhado)
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	async imprimirDetalhadoRelatorioFechamento(){
		const turnoDeVenda = (await this.findTurnoDeVendaUseCase.find({ fechamento: this.idFechamento })).content[0]
		try {
			await imprimirDetalhadoRelatorioFechamento(turnoDeVenda, this.fechamentoDetalhado)
		} catch (e) {
			AlertModule.setError(e)
		}
	}
	
	mostrarDialogoDeUploadDeArquivos() {
		this.dialogoDeImportadorDeArquivos.mostrar(this.fechamentoDetalhado)
	}
	
	abrirDialogoDeNovaMovimentacaoDeCaixa(tipo: TipoMovimentacao) {
		this.movimentacaoInicial.tipoMovimentacao = tipo
		this.mostrarDialogoDeNovaMovimentacao = true
	}
	
	async atualizaTurnoDeFechamento() {
		this.fechamentoDetalhado = await this.findFechamentoUseCase.get(this.idFechamento)
	}

	async carregar() {
		try {
			this.carregando = true
			this.fechamentoDetalhado = await this.findFechamentoUseCase.get(this.idFechamento)

			if(this.fechamentoDetalhado.turnoDeVenda.id){
				const movimentacoesDoTurno = await this.findTurnoDeVendaUseCase.getMovimentacoesCaixaByTurnoId(this.fechamentoDetalhado.turnoDeVenda.id)

				this.fechamentoDetalhado.turnoDeVenda.movimentacoes = movimentacoesDoTurno
			}

		} catch (error: any) {
			this.erroAoCarregar = error
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	@Watch('mostra')
	async onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.carregar()
	}

	@Watch('fechamentoDetalhado', { deep: true })
	onChangeFechamento(fechamento: FechamentoDeCaixaDetalhado | null) {
		this.$emit('input', fechamento)
	}
}

